import { join } from '@pepita/querystring';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { DISCOVER_ITEM_TYPES } from 'src/types/homepage';

const isInternationalSearchRemoveHeaderLinkEnabled = isFeatureEnabled(
  'INTERNATIONAL_SEARCH_REMOVE_HEADER_LINK'
);

export const urls = {
  publishAdsUrl: '/utente/annuncio/nuovo',
  publishedAdsLandingUrl: '/pubblica-annuncio/', //todo: quando sarà online la landing del pubblica rivedere tutte le entry
  secretPropertiesUrl: '/immobili-segreti/',
  mortgageUrl: '/mutui/',
  mortgagePreApproveUrl: '/mutui/pre-approvazione-online',
  evaluateBuildingUrl: '/valutazione-immobiliare/',
  sellerLeadUrlOldMenu: '/vendi-immobile/',
  sellerLeadUrl: '/vendi-immobile/vendi-inizio',
  cityGuideUrl: '/mercato-immobiliare/',
  insightsUrl: '/insights/',
  searchAgenciesUrl: '/agenzie-immobiliari/',
  myHomeUrl: '/la-mia-casa/',
  agencyUrl: '/agenzie-immobiliari/',
  agencyUrlPro: '/pro/',
  agencyUrlConstructor: '/imprese-edili/',
  associationUrl: '/agenzie-immobiliari/associazioni/',
  franchisingUrl: '/agenzie-immobiliari/gruppi/',
  agencyAgentsUrl: '/agenti-immobiliari/',
  searchAbroadUrl: '/internazionale/',
  whoWeAreUrl: '/info/chi-siamo/',
  antiScamUrl: '/info/consigli-antitruffa/',
  euroansaUrl: '/euroansa/',
  contactUsUrl: '/info/contattaci/',
  userAssistance: 'https://assistenza.immobiliare.it/',
  internationalLuxuryUrl: '/info/internazionale-lusso/',
  networkGroupUrl: '/info/network-gruppo/',
  professionalReportUrl: '/info/report-professionali/',
  academyUrl: 'https://academy.immobiliare.it/',
  virtualTourUrl: '/info/virtual-tour/',
  immoVisitaUrl: '/info/immovisita/',
  immoSoftwareUrl: '/info/software-immobiliare/',
  racSoftwareUrl: '/info/software-gestionali-consigliati/',
  publishAdUrl: '/info/pubblicazione-annunci/',
  workWithUsUrl: '/info/lavora-con-noi/',
  advertisingUrl: '/info/pubblicita/',
  pressOfficeUrl: '/info/ufficio-stampa/',
  leaderUrl: '/info/leader/',
  newsUrl: '/news/',
};

export const headerUrls = [
  ...(!isInternationalSearchRemoveHeaderLinkEnabled && urls.searchAbroadUrl
    ? [
        {
          text: 'header_vert_foreign_countries',
          url: urls.searchAbroadUrl,
        },
      ]
    : []), // i18n-extract-keys ["header_vert_foreign_countries"]
  ...(urls.searchAgenciesUrl
    ? [
        {
          text: 'header_vert_agencies',
          url: urls.searchAgenciesUrl,
        },
      ]
    : []), // i18n-extract-keys ["header_vert_agencies"]
  ...(urls.cityGuideUrl
    ? [
        {
          text: 'ftr_anchor_house_prices',
          url: urls.cityGuideUrl,
          isClientSideNavigation: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_prices"]
  ...(urls.evaluateBuildingUrl
    ? [
        {
          text: 'ftr_slim_appraise_house',
          url: join(urls.evaluateBuildingUrl, 'from=navbar'),
        },
      ]
    : []), // i18n-extract-keys ["ftr_slim_appraise_house"]
  ...(urls.mortgageUrl
    ? [
        {
          text: 'ftr_slim_mortgages',
          url: urls.mortgageUrl,
        },
      ]
    : []), // i18n-extract-keys ["ftr_slim_mortgages"]
];

export const alternateHeaderUrls = [
  ...(urls.searchAgenciesUrl
    ? [
        {
          text: 'header_vert_agencies',
          url: urls.searchAgenciesUrl,
        },
      ]
    : []), // i18n-extract-keys ["header_vert_agencies"]
  ...(urls.cityGuideUrl
    ? [
        {
          text: 'ftr_anchor_house_prices',
          url: urls.cityGuideUrl,
          isClientSideNavigation: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_prices"]
  ...(urls.evaluateBuildingUrl
    ? [
        {
          text: 'ftr_slim_appraise_house',
          url: join(urls.evaluateBuildingUrl, 'from=navbar'),
        },
      ]
    : []), // i18n-extract-keys ["ftr_slim_appraise_house"]
];

export const discoverUrls = [
  {
    title: 'feature_strip_evaluate_title',
    text: 'feature_strip_evaluate_text',
    action: 'feature_strip_evaluate_act',
    image: '',
    url:
      urls.evaluateBuildingUrl &&
      join(urls.evaluateBuildingUrl, 'from=services'),
    type: DISCOVER_ITEM_TYPES.EVALUATION_BUILDING,
  }, // i18n-extract-keys ["feature_strip_evaluate_title", "feature_strip_evaluate_text", "feature_strip_evaluate_act"]
  {
    title: 'feature_strip_building_prices_title',
    text: 'feature_strip_building_prices_text',
    action: 'feature_strip_building_prices_act',
    image: '',
    url: urls.cityGuideUrl,
    type: DISCOVER_ITEM_TYPES.CITYGUIDE,
  }, // i18n-extract-keys ["feature_strip_building_prices_title", "feature_strip_building_prices_text", "feature_strip_building_prices_act"]
  {
    title: 'feature_strip_my_home_title',
    text: 'feature_strip_my_home_text',
    action: 'feature_strip_my_home_act',
    image: '',
    url: urls.myHomeUrl && join(urls.myHomeUrl, 'from=services'),
    type: DISCOVER_ITEM_TYPES.MYHOME,
    isNew: true,
  }, // i18n-extract-keys ["feature_strip_my_home_title", "feature_strip_my_home_text", "feature_strip_my_home_act"]
];

export const alternateDiscoverUrls = [
  {
    title: 'feature_strip_evaluate_title',
    text: 'feature_strip_evaluate_text',
    action: 'feature_strip_evaluate_act',
    image: '',
    url:
      urls.evaluateBuildingUrl &&
      join(urls.evaluateBuildingUrl, 'from=services'),
    type: DISCOVER_ITEM_TYPES.EVALUATION_BUILDING,
  }, // i18n-extract-keys ["feature_strip_evaluate_title", "feature_strip_evaluate_text", "feature_strip_evaluate_act"]
  {
    title: 'feature_strip_building_prices_title',
    text: 'feature_strip_building_prices_text',
    action: 'feature_strip_building_prices_act',
    image: '',
    url: urls.cityGuideUrl,
    type: DISCOVER_ITEM_TYPES.CITYGUIDE,
  }, // i18n-extract-keys ["feature_strip_building_prices_title", "feature_strip_building_prices_text", "feature_strip_building_prices_act"]
  {
    title: 'feature_strip_publish_title',
    text: 'feature_strip_publish_text',
    action: 'feature_strip_publish_act',
    image: '',
    url:
      urls.publishedAdsLandingUrl &&
      join(urls.publishedAdsLandingUrl, 'from=services'),
    type: DISCOVER_ITEM_TYPES.PUBLISH_AD,
  }, // i18n-extract-keys ["feature_strip_publish_title", "feature_strip_publish_text", "feature_strip_publish_act"]
];
